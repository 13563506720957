/*Component for About page of the website*/

import React, {Component} from 'react';
import './About.css';
import './CPS643.css'
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import Footer from "./Footer/Footer";

class CPS643 extends Component {
    render(){
        return(
            <div className="About-wrap">
                <Container className="About">
                    <Row>
                        <Col>
                            <h1 className="Title-about"> Ryerson University, Department of Computer Science </h1>
                            <h2 className="Title-about">CPS643 - Virtual Reality</h2>
                            <h3 className="Title-about">Studio VR</h3>
                            <h4 className="Title-about">Akash Chhabria</h4>
                        </Col>
                    </Row>
                    {/*Text*/}
                    <Row>
                        <Col className={"Info-wrap-643"}>
                            <p className={"Text-643"}>Studio VR is a fully interactive Music Studio simulator. The idea is to let users create multiple instruments and play them the way they would like!</p>
                            <p className={"Text-643"}>This version currently has 3 types of instruments: Drums, Keyboard, and Synthesizer.The cool thing about this project is you can create as many instruments you want and place them anywhere on the scene</p>
                            <p className={"Text-643"}>The walls in this game also visualize the audio from the music being played to make the environment more exciting!</p>
                            <p className={"Text-643"}>This project was made using a simulated VR sequence but can be easily adapted to VR. To learn more, click on the YouTube video below!</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Image className="Photo-Images" src={"assets/CPS643_2.png"}/>
                            <caption className="Text">Figure 1: Shows multiple instruments displayed in one scene</caption>
                        </Col>
                        <Col lg={6}>
                            <Image className="Photo-Images" src={"assets/CPS643_3.png"}/>
                            <caption className="Text">Figure 2: shows the walls acting as Audio Visualizers when the keyboard notes are hit</caption>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}/>
                        <Col lg={4}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/FD7aPMjnDXs" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </Col>
                        <Col lg={4}/>
                    </Row>

                    {/*Visit my Website button*/}
                    <Row className="btn-wrapper-wrap-about">
                        <Col lg={4}/>
                        <Col lg={4} className="btn-wrapper">
                            <Link to='/' className={"anchor"}><Button className="btn" block>Visit My Website</Button></Link>
                        </Col>
                        <Col lg={4}/>
                    </Row>
                    <Footer/>
                </Container>
            </div>
        );
    }
}

export default CPS643;
