/*Component for home page of website, Uses Vanta.js Integration*/

import React, {Component} from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import './Home.jsx'
import './Continue.css';
import {Link} from "react-router-dom";
import Header from "./Header";
import Particles from "react-particles-js";
import NET from 'vanta/src/vanta.net.js';

class Continue extends Component {
    /*Backend code for Vanta.js*/
    constructor() {
        super();
        this.vantaRef = React.createRef();
    }
    componentDidMount() {
        this.vantaEffect = NET({
            el: this.vantaRef.current,
            color: 0xffffff,
            backgroundColor: 0x2a2c2f,
            points: 11.00,
        })
    }
    componentWillUnmount() {
        if (this.vantaEffect) {this.vantaEffect.destroy();}
    }

    render() {
        return (
            <div className="Continue">
                {/*div for Vanta.js*/}
                <div ref={this.vantaRef} className="BG-animation" />
                {/*<Particles className="BG-animation"
                           params={{
                               "particles": {
                                   "number": {
                                       "value": 160,
                                       "density": {
                                           "enable": false
                                       }
                                   },
                                   "size": {
                                       "value": 10,
                                       "random": true
                                   },
                                   "move": {
                                       "direction": "bottom",
                                       "out_mode": "out"
                                   },
                                   "line_linked": {
                                       "enable": false
                                   }
                               },
                               "interactivity": {
                                   "events": {
                                       "onclick": {
                                           "enable": true,
                                           "mode": "remove"
                                       }
                                   },
                                   "modes": {
                                       "remove": {
                                           "particles_nb": 10
                                       }
                                   }
                               }
                           }} />*/}
                {/*Calls title Component*/}

                <Header/>
                <Container>
                    <Row className="btn-wrapper-wrap">
                        <Col lg={4}/>
                        <Col lg={4} className="btn-wrapper">
                            <Link to='/Options' className={"anchor"}><Button className="btn" block>Continue</Button></Link>
                        </Col>
                        <Col lg={4}/>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Continue;
